@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.FormationsRow {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: func.rem(24px);
}
.FormationsStats {
  flex: 1;
  max-width: func.rem(360px);
  margin-top: func.rem(30px);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: func.rem(8px);

  &-playersWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    background-color: var.$colorWhite;
    border-radius: var.$defaultBorderRadius;
    padding: func.rem(8px);
    grid-column: span 2;
  }
  &-player {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-top: func.rem(-25px);
    &--number {
      width: func.rem(40px);
      height: func.rem(40px);
      border-radius: 100vw;
      background-color: var.$colorTextPrimary;
      display: grid;
      place-items: center;
      font-size: func.rem(14px);
      font-weight: var.$fontSemibold;
      color: var.$colorWhite;
      margin-bottom: func.rem(8px);
    }
    p {
      font-size: func.rem(14px);
      font-weight: var.$fontNormal;
    }
    &--stats {
      font-size: func.rem(12px) !important;
      color: var.$colorTextSecondary;
      margin-top: func.rem(4px);

      span {
        color: var.$colorTextPrimary;
      }
    }
  }
}
