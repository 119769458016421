@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsBox {
  padding: func.rem(10px);
  border-radius: var.$defaultBorderRadius;
  background-color: var.$colorWhite;
  box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
  flex: 1;

  &:not(.disabled) {
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.35);
    }
  }

  &.big {
    .StatsBox-stats {
      font-size: func.rem(32px);
    }
  }

  &-title {
    font-size: func.rem(12px);
    color: var.$colorTextSecondary;
    text-transform: uppercase;
    text-align: center;
  }
  &-stats {
    font-size: func.rem(20px);
    text-align: center;
    margin-bottom: func.rem(8px);
    margin-top: func.rem(4px);
  }
  &-line {
    height: func.rem(8px);
    background-color: var.$colorBackground;
    border-radius: var.$smallBorderRadius;
    position: relative;
  }
  &-highlightLine {
    position: absolute;
    top: 0;
    bottom: 0;

    &.home {
      border-top-left-radius: var.$smallBorderRadius;
      border-bottom-left-radius: var.$smallBorderRadius;
      border-right: 2px solid var.$colorWhite;
      left: 0;
    }
    &.guest {
      border-top-right-radius: var.$smallBorderRadius;
      border-bottom-right-radius: var.$smallBorderRadius;
      right: 0;
    }
  }
}
