@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Formations {
  max-height: calc(100vh - 42px - 130px - 72px);
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  gap: func.rem(12px);
}
