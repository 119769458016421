@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.VideoHeader {
  display: flex;
  flex-flow: column nowrap;
  gap: func.rem(12px);
  align-items: center;

  &-player {
    color: var.$colorWhite;
    font-size: func.rem(20px);

    span {
      color: var.$colorWhite;
      font-weight: var.$fontNormal;
    }
  }
  &-infoContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: func.rem(24px);
  }
  &-infoItem {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(8px);

    &--title {
      text-transform: uppercase;
      font-size: func.rem(12px);
      color: var.$colorTextSecondary;
    }

    &--value {
      font-size: func.rem(12px);
      color: var.$colorWhite;
    }
  }
}
