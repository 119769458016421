@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Wrapper {
  position: relative;
  .highlight {
    position: absolute;
    left: 0;
    top: 0;
    background-color: var.$colorGrey;
    border-radius: var.$mediumBorderRadius;
    z-index: 0;
    visibility: hidden;
  }

  &.active .highlight {
    visibility: visible;
  }
  .SelectTab {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: var.$colorGrey3;
    padding: func.rem(8px);
    border-radius: var.$mediumBorderRadius;
    gap: func.rem(12px);
    cursor: pointer;
    position: relative;
    z-index: 2;

    p {
      font-size: func.rem(12px);
      white-space: nowrap;
      max-width: 100px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    &.main {
      margin-bottom: func.rem(16px);
    }
    &.active {
      background-color: var.$colorTextPrimary;

      p {
        color: var.$colorWhite;
      }
    }
    &.vertical {
      flex-flow: column-reverse nowrap;
      height: func.rem(168px);
      p {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
        max-height: 100px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &.disabled {
      pointer-events: none;

      p {
        color: var.$colorTextSecondary;
      }
    }
  }
}
