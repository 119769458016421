@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.GoalkeepersMapStats {
  padding: func.rem(10px) func.rem(14px);
  position: absolute;
  font-size: func.rem(20px);
  border-radius: var.$mediumBorderRadius;

  &.goal {
    background-color: var.$colorHighlightRed1;
  }
}
