@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.HeadToHeadTeamPanel {
  width: func.rem(168px);
  height: func.rem(168px);
  position: relative;
  background-image: url('../../../Assets/icons/line.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size:
    100% 100%,
    auto;

  &-team {
    position: absolute;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: func.rem(8px);

    p {
      font-size: func.rem(20px);
    }
    .dot {
      width: func.rem(8px);
      height: func.rem(8px);
      border-radius: 50%;
    }
    &.left {
      left: 0;
      bottom: 0;
    }
    &.top {
      top: 16px;
      right: -16px;
      flex-flow: row-reverse nowrap;
      transform: rotate(-90deg);
    }
  }
  &-switchButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: func.rem(42px);
    height: func.rem(42px);
    border-radius: 50%;
    background-color: var.$colorWhite;
    display: grid;
    place-items: center;
    box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
    cursor: pointer;

    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.35);
    }
  }
}
