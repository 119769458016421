@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Momentum {
  height: calc(100vh - 42px - 90px - 72px);
  overflow-y: auto;
  box-shadow: inset 0 4px 8px 0px rgba(#868e96, 0.12);
  padding: func.rem(16px) 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
