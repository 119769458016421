@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Buttons {
  border-radius: var.$mediumBorderRadius;
  white-space: nowrap;
  flex: 1;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  gap: func.rem(8px);

  &.black {
    &.contained {
      background-color: var.$colorTextPrimary;

      p {
        color: var.$colorWhite;
      }
      &:hover,
      &:focus {
        background-color: lighten(var.$colorTextPrimary, 3);
      }
    }
    &.outlined {
      border: 2px solid var.$colorTextPrimary;

      p {
        color: var.$colorTextPrimary;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorBackground, 3);
      }
    }
  }

  &.grey {
    &.contained {
      background-color: var.$colorGrey3;

      p {
        color: var.$colorTextPrimary;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorGrey3, 3);
      }
    }
    &.outlined {
      border: 2px solid var.$colorGrey3;

      p {
        color: var.$colorGrey3;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorBackground, 3);
      }
    }
  }

  &.main {
    &.contained {
      background-color: var.$colorHighlightPrimary;

      p {
        color: var.$colorWhite;
      }
      &:hover,
      &:focus {
        background-color: lighten(var.$colorHighlightPrimary, 3);
      }
    }
    &.outlined {
      border: 2px solid var.$colorHighlightPrimary;

      p {
        color: var.$colorHighlightPrimary;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorBackground, 3);
      }
    }
  }

  &.background {
    &.contained {
      background-color: var.$colorBackground;

      p {
        color: var.$colorTextPrimary;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorBackground, 3);
      }
    }
    &.outlined {
      border: 2px solid var.$colorTextPrimary;
      background-color: var.$colorBackground;

      p {
        color: var.$colorTextPrimary;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorBackground, 3);
      }
    }
  }

  &.loginBlue {
    &.contained {
      background-color: var.$colorLoginBlue;

      p {
        color: var.$colorWhite;
      }
      &:hover,
      &:focus {
        background-color: var.$colorLoginBlue3;
      }
    }
    &.outlined {
      border: 2px solid var.$colorLoginBlue;

      p {
        color: var.$colorLoginBlue;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorBackground, 3);
      }
    }
  }

  &.white {
    &.contained {
      background-color: var.$colorWhite;

      p {
        color: var.$colorTextPrimary;
      }
      &:hover,
      &:focus {
        background-color: darken(var.$colorWhite, 3);
      }
    }
    &.outlined {
      border: 2px solid var.$colorWhite;

      p {
        color: var.$colorWhite;
      }
      &:hover,
      &:focus {
        background-color: lighten(var.$colorTextPrimary, 10);
      }
    }
  }

  &.disabled {
    border: unset !important;
    background-color: var.$colorGrey3 !important;
    color: var.$colorTextSecondary !important;
    pointer-events: none;
    opacity: 0.6;
  }
}
