@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Loading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  &-inner {
    border-radius: 16px;
    background-color: rgba(21, 29, 34, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    gap: 20px;

    svg {
      margin-top: func.rem(5px);
      width: 60px;
      height: 60px;
      animation: spin 2s linear infinite;
      animation-direction: reverse;
    }

    span {
      font-size: func.rem(16px);
      font-weight: 500;
      color: #ffffff;
      display: block;
      text-align: center;
      font-weight: bold;
    }

    @keyframes spin {
      100% {
        transform: rotate(360deg);
      }
    }
  }
}
