@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Goalkeepers {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: func.rem(24px);

  &-stats {
    flex: 1;
    display: flex;
    flex-flow: column nowrap;
    gap: func.rem(8px);
    max-width: func.rem(360px);
    margin-top: func.rem(74px);
  }
}
