@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.FiltersContainer {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: func.rem(24px);
  background-color: var.$colorWhite;
  padding-bottom: func.rem(8px);
  box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
  z-index: 2;
}

.FiltersRow {
  display: flex;
  flex-flow: row wrap;
  gap: func.rem(8px);
}
