@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.FaceOffMap {
  position: relative;

  &-team {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: var.$colorTextPrimary;
    color: var.$colorWhite;
    font-size: func.rem(11px);
    border-radius: 100px;
    padding: func.rem(4px) func.rem(32px);

    &.home {
      left: -40px;
      transform: rotate(-90deg);
    }
    &.away {
      right: -40px;
      transform: rotate(90deg);
    }
  }
  &-info {
    position: absolute;
    background-color: var.$colorWhite;
    border-radius: var.$defaultBorderRadius;
    box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
    padding: func.rem(8px) func.rem(12px);
    max-width: func.rem(180px);
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: func.rem(12px);
    font-weight: var.$fontNormal;
    text-align: center;
  }
  &-part {
    cursor: pointer;

    text {
      font-size: func.rem(20px);
    }
    &.disabled {
      .outerCircle {
        stroke: var.$colorGrey3;
      }
      .innerCircle {
        fill: var.$colorGrey3;
      }
      path {
        fill: var.$colorGrey3;
      }
    }
  }
}
