@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.ShotsPopup {
  background-color: var.$colorTextPrimary;
  padding: func.rem(8px) func.rem(16px);
  border-radius: var.$defaultBorderRadius;
  position: absolute;
  z-index: 2;

  &-player {
    font-size: func.rem(12px);
    color: var.$colorWhite;
    font-weight: var.$fontNormal;
    white-space: nowrap;
    text-align: center;

    span {
      font-weight: var.$fontSemibold;
      color: var.$colorWhite;
    }
  }
  &-stats {
    margin-top: func.rem(10px);
    margin-bottom: func.rem(14px);
    p {
      font-size: func.rem(10px);
      font-weight: var.$fontNormal;
      color: var.$colorTextSecondary;
      white-space: nowrap;
      text-align: center;

      span {
        color: var.$colorWhite;
      }
    }
  }
  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 6px;
    border-style: solid;
    border-color: var.$colorTextPrimary transparent transparent transparent;
  }
  &-video {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;

    border-left: 8px solid var.$colorWhite;
  }
  .Buttons {
    margin-inline: auto;
  }
}
