@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Sidebar {
  &-header {
    padding: 0 func.rem(16px);
    margin-bottom: func.rem(20px);
  }

  &-content {
    width: 480px;
    padding: 0 func.rem(20px) func.rem(20px);

    h1 {
      font-size: func.rem(28px);
      margin-bottom: func.rem(12px);
      color: var.$colorHighlightPrimary;
    }

    p {
      font-weight: var.$fontNormal;
    }
  }

  .MuiButton-text {
    color: var.$colorTextPrimary;
    text-transform: none;
    font-size: func.rem(12px);
    font-weight: var.$fontSemibold;

    &:hover,
    &:focus {
      background-color: unset;
    }
  }
}
