@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsDetailModal {
  .MuiPaper-root {
    border-radius: var.$defaultBorderRadius;
    background-color: var.$colorBackground;
    padding: func.rem(20px);
  }

  &-data {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(8px);
    margin: func.rem(24px) 0;
    min-height: 288px;

    &--section {
      flex: 1;
      background-color: var.$colorWhite;
      border-radius: var.$defaultBorderRadius;
      overflow: hidden;
      padding: func.rem(8px);
      display: flex;
      flex-flow: column nowrap;
      gap: func.rem(8px);
      height: fit-content;
    }
  }

  &-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .MuiButtonBase-root {
      color: var.$colorTextPrimary;
      text-transform: none;
      font-size: func.rem(12px);
      padding: func.rem(6px) func.rem(42px);
      border-color: var.$colorTextPrimary;

      &:hover,
      &:focus {
        border-color: var.$colorTextPrimary;
        background-color: darken(var.$colorWhite, 10);
      }
    }
  }
}
