/* Colors */
$colorTextPrimary: #0b0d0e;
$colorTextSecondary: #868e96;
$colorBackground: #f1f3f5;
$colorWhite: #fff;
$colorBlack: #000;
$colorGrey: #dee2e6;
$colorGrey2: #e9ecef;
$colorGrey3: #ced4da;
$colorSuccess: #12b886;
$colorError: #e03131;
$colorDot: #c1c2c2;
$colorDotGoal: #cdd6f6;
$colorDotCatched: #f7cbcb;
$colorDotBlocked: #c3ede1;
$colorHighlightRed1: #ffe3e3;
$colorHighlightRed2: #ffc9c9;
$colorHighlightRed3: #ff8787;
$colorHighlightRed4: #fa5252;
$colorHighlightRed5: #e03131;
$colorHighlightRed6: #ff6b6b;
$colorHighlightGreen1: #c3fae8;
$colorHighlightGreen2: #96f2d7;
$colorHighlightGreen3: #38d9a9;
$colorHighlightGreen4: #12b886;
$colorHighlightGreen5: #099268;
$colorHighlightGreen6: #20c997;
$colorHighlightPrimary: #3b5bdb;
$colorHighlightSecondary: #942bf0;
$colorStats1: #63e6be;
$colorStats2: #96f2d7;
$colorStats3: #c3fae8;
$colorStats4: #e6fcf5;
$colorIceTimeHome: #099268;
$colorIceTimeGuest: #e02020;

$colorLoginBackground: #f1f5f9;
$colorLoginText: #020617;
$colorLoginBlue: #035fa1;
$colorLoginBlue2: #bae6fd;
$colorLoginBlue3: #0ea5e9;
$colorLoginRed: #ef4444;
$colorLoginRed2: #fecaca;
$colorLoginGray: #94a3b8;
$colorLoginGray2: #cbd5e1;

/* Font weight */
$fontNormal: 400;
$fontMedium: 500;
$fontSemibold: 700;

/* Border radius */
$defaultBorderRadius: 8px;
$mediumBorderRadius: 4px;
$smallBorderRadius: 2px;
/* Widths */
$filterMinWidth: 80px;
$contentMaxWidth: 732px;
