@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.HeadToHeadCharts {
  max-width: func.rem(1080px);
  width: fit-content;
  margin-inline: auto;
  display: grid;
  grid-template-columns: func.rem(168px) 1fr;
  gap: func.rem(16px);

  &-players {
    display: flex;
    gap: 8px;

    &.top {
      flex-flow: row nowrap;
      order: 2;
    }
    &.left {
      flex-flow: column nowrap;
      order: 3;
    }
  }
  &-charts {
    order: 4;
    display: grid;
    grid-template-columns: repeat(auto-fill, 32px);
    gap: 8px;
    z-index: 2;

    &.top {
      transform: rotate3d(1, 1, 0, 180deg);
      -webkit-transform: rotate3d(1, 1, 0, 180deg);
    }
  }
}
