@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Matches {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  min-height: 100vh;
  background-color: var.$colorBackground;
  padding: 0 0 func.rem(74px) 0;

  &-header {
    width: 100%;
    margin-bottom: func.rem(74px);
    padding: func.rem(6px) func.rem(20px);
    background-color: var.$colorWhite;
    box-shadow: 0px 4px 12px 0 rgba(#868e96, 0.12);
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(20px);

    .MuiButton-text {
      color: var.$colorTextPrimary;
      text-transform: none;
      font-size: func.rem(12px);
      font-weight: var.$fontSemibold;

      &:hover,
      &:focus {
        background-color: unset;
      }
    }
  }
  &-title {
    font-size: func.rem(32px);
    margin-bottom: func.rem(54px);
  }
  &-matchesList {
    display: flex;
    flex-flow: column nowrap;
    gap: func.rem(12px);
    max-width: func.rem(744px);
  }
  &-noData {
    max-width: func.rem(744px);
    height: func.rem(225px);
    border: 2px solid var.$colorGrey;
    border-radius: var.$defaultBorderRadius;
    display: grid;
    place-items: center;

    h1 {
      text-align: center;
      width: 80%;
      color: var.$colorTextSecondary;
    }
  }
}
