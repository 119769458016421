@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Filter {
  background-color: var.$colorGrey;
  min-width: func.rem(var.$filterMinWidth);
  border-radius: var.$defaultBorderRadius;
  cursor: pointer;

  p {
    font-size: func.rem(12px);
    text-align: center;
    white-space: nowrap;
  }
  &--player-row {
    min-width: 141px;
  }
  &:not(.active):hover,
  &:not(.active):focus {
    background-color: darken(var.$colorGrey, 2.75);
  }
  &.active {
    background-color: var.$colorTextPrimary;

    p {
      color: var.$colorWhite;
    }
  }
  &.disabled {
    pointer-events: none;

    p {
      color: var.$colorTextSecondary;
    }
  }
}
