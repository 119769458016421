@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsBoxSimple {
  padding: func.rem(10px);
  border-radius: var.$defaultBorderRadius;
  background-color: var.$colorWhite;
  box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
  //flex: 1;

  &-title {
    font-size: func.rem(12px);
    color: var.$colorTextSecondary;
    text-transform: uppercase;
    text-align: center;
  }
  &-stats {
    font-size: func.rem(20px);
    text-align: center;
    margin-top: func.rem(4px);
  }
  &.disabled {
    .StatsBoxSimple-stats {
      color: var.$colorTextSecondary;
    }
  }
}
