@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.PieChartWrapper {
  width: func.rem(32px);
  height: func.rem(32px);
  display: grid;
  place-items: center;

  &-empty {
    width: func.rem(8px);
    height: func.rem(8px);
    border-radius: 50%;
    background-color: var.$colorTextSecondary;
  }

  .recharts-surface {
    border-radius: 50%;
  }
}
