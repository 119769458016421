@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.PlayerPickerRow {
  display: flex;
  flex-flow: row nowrap;
  gap: func.rem(8px);
  align-items: flex-start;

  &-players {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(8px);
  }
}
