@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsDetailHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  &-team {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: func.rem(16px);

    p {
      font-size: func.rem(32px);
    }

    img {
      height: func.rem(48px);
      width: auto;
    }
  }
  &-center {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: func.rem(6px);
    &--title {
      color: var.$colorTextSecondary;
      text-transform: uppercase;
      font-size: func.rem(12px);
    }
    &--stats {
      font-size: func.rem(22px);
    }
  }
}
