@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.PlayerPickerModal {
  &-body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: func.rem(24px);

    &--part {
      display: flex;
      flex-flow: column nowrap;
      gap: func.rem(28px);
    }
  }
}
