@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Input {
  display: flex;
  flex-flow: column nowrap;
  &-title {
    font-size: func.rem(12px);
    padding-bottom: func.rem(4px);
  }
  input {
    outline: 0;
    border: 2px solid var.$colorBlack;
    border-radius: var.$mediumBorderRadius;
    padding: func.rem(14px);
    flex: 1;

    &::placeholder {
      color: var.$colorTextSecondary;
      font-size: func.rem(14px);
    }
  }
}
