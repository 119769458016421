@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.IceTimeRow {
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;

  &-player {
    width: 350px;
    background-color: var.$colorWhite;
    border-radius: var.$defaultBorderRadius;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: func.rem(8px);
    box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);

    p {
      font-size: func.rem(12px);
      font-weight: var.$fontSemibold;
    }
    &--stats {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: func.rem(1px);

      p {
        width: 40px;
        margin-right: func.rem(8px);
        text-align: center;
      }
    }
    &--statBox {
      width: 40px;
      height: 18px;
      border-radius: var.$mediumBorderRadius;
      background-color: var.$colorTextPrimary;
      font-size: func.rem(10px);
      color: var.$colorWhite;
      display: grid;
      place-items: center;

      &:nth-of-type(2) {
        background-color: var.$colorHighlightGreen5;
      }
      &:nth-of-type(3) {
        background-color: var.$colorHighlightRed5;
      }
    }
  }
  &-timeline {
    flex: 1;
    height: 36px;
    padding-inline: func.rem(8px);
    background-color: var.$colorWhite;
    border-radius: var.$defaultBorderRadius;
    display: flex;
    flex-flow: row;
    position: relative;
    box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);

    &--block {
      flex: 1;
      height: 36px;
      position: relative;

      &::after {
        position: absolute;
        content: '';
        width: 2px;
        height: 36px;
        right: 0;
        top: 0;
        background-color: var.$colorGrey3;
      }

      &:first-of-type::before {
        position: absolute;
        content: '';
        width: 2px;
        height: 36px;
        left: 0;
        top: 0;
        background-color: var.$colorTextPrimary;
        z-index: 10;
      }
      &:last-of-type::after {
        background-color: var.$colorTextPrimary;
        z-index: 10;
      }
    }
  }
  &-state {
    position: absolute;
    height: 18px;
    top: 50%;
    transform: translateY(-50%);
  }
}
