@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.ShotsDetailModal {
  &-body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: fit-content;
    margin-inline: auto;

    &--container {
      position: relative;
      height: 585px;
    }
    &--stats {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-around;
      gap: func.rem(26px);
      background-color: var.$colorTextPrimary;
      margin-top: -5px;
      width: 100%;
      padding: func.rem(8px) func.rem(12px);
      border-bottom-left-radius: var.$defaultBorderRadius;
      border-bottom-right-radius: var.$defaultBorderRadius;
      z-index: 2;
    }
    &--statsItem {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;

      p {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: func.rem(4px);
        text-transform: uppercase;
        font-size: func.rem(12px);
        font-weight: var.$fontSemibold;
        color: var.$colorWhite;
        margin-bottom: func.rem(4px);
      }
      h3 {
        font-size: func.rem(20px);
        font-weight: var.$fontSemibold;
        color: var.$colorWhite;
      }
    }
  }
}
