@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.FaceOff {
  &-stats {
    display: flex;
    flex-flow: column nowrap;
    max-width: func.rem(730px);
    margin-inline: auto;
    margin-bottom: func.rem(24px);
    gap: func.rem(8px);

    .StatsBox {
      margin-inline: auto;
      width: func.rem(360px);
    }
    &--simple {
      display: flex;
      flex-flow: row wrap;
      gap: func.rem(8px);

      .StatsBoxSimple {
        flex: 1;
      }
    }
  }
  &-body {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: func.rem(40px);

    &--players {
      flex: 1;
      display: flex;
      flex-flow: column nowrap;
      gap: func.rem(8px);
      max-width: func.rem(220px);
    }
  }
  .Buttons {
    margin: func.rem(24) auto 0 auto;
  }
}
