@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.PickedPlayer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var.$colorWhite;
  border-radius: var.$defaultBorderRadius;
  padding: func.rem(10px) func.rem(8px);
  box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
  cursor: pointer;

  &.active {
    background-color: var.$colorTextPrimary;

    p,
    span {
      color: var.$colorWhite;
    }
  }

  &:not(.active):hover,
  &:not(.active):focus {
    background-color: darken(var.$colorWhite, 3);
  }

  &-info {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(6px);

    p {
      font-size: func.rem(12px);
      font-weight: var.$fontSemibold;
      color: var.$colorTextPrimary;

      span {
        font-weight: var.$fontNormal;
      }
    }
  }
  &-stats {
    color: var.$colorTextSecondary;
    font-size: func.rem(12px);
    font-weight: var.$fontSemibold;

    span {
      color: var.$colorTextPrimary;
    }
  }
}
