@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.VideoModal {
  .MuiPaper-root {
    display: block;
    border-radius: var.$defaultBorderRadius;
    background-color: var.$colorTextPrimary;
    padding: func.rem(24px) func.rem(56px);
  }
  &-video {
    position: relative;
    margin-top: func.rem(24);
    margin-bottom: func.rem(16);
    border-radius: var.$defaultBorderRadius;
    overflow: hidden;
    aspect-ratio: 16 / 9;
    max-height: 50vh;
    margin-inline: auto;
  }
  &-footer {
    display: flex;
    justify-content: center;
    position: relative;

    &--bugReport {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      .MuiButton-text {
        color: var.$colorWhite;
        text-transform: none;
        font-size: func.rem(12px);
        font-weight: var.$fontSemibold;

        &:hover,
        &:focus {
          background-color: unset;
        }
      }
    }
  }
  &-controls {
    background-color: rgba(var.$colorWhite, 0.5);
    display: grid;
    place-items: center;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
  }
}
