@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.IceTime {
  height: calc(100vh - 42px - 130px - 70px);
  padding: 0;
  display: flex;
  flex-flow: column nowrap;
}
.IceTimeTable {
  flex: 1;
  padding: func.rem(16px) func.rem(40px);
  max-height: calc(100vh - 42px - 130px - 70px - 102px);
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  gap: func.rem(8px);
}
