@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Shots {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: func.rem(24px);

  &-center {
    flex: 1;
    max-width: func.rem(360px);

    .Buttons {
      margin-inline: auto;
      margin-top: func.rem(24px);
    }

    &--disclaimer {
      display: block;
      width: 100%;
      text-align: center;
      color: var.$colorTextSecondary;
      font-size: func.rem(12px);
      margin-bottom: func.rem(8px);
    }
  }
}
