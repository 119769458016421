@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsModal {
  h1 {
    text-align: center;
    margin-bottom: func.rem(20px);
  }
  &-tableContainer {
    overflow-y: auto;
    max-height: 600px;
  }
  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      padding: func.rem(6px) func.rem(12px);
    }
    th {
      cursor: pointer;
      background-color: var.$colorGrey3;
      font-size: func.rem(14px);
      position: sticky;
      top: 0;
    }
    td {
      font-size: func.rem(12px);
    }
    tbody {
      tr:nth-of-type(even) {
        background-color: var.$colorGrey;
      }
    }
  }
}
