@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Layout {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;

  &-body {
    display: flex;
    flex: 1;
    background-color: var.$colorBackground;
  }
}
