@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.Switch {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: func.rem(8px);
  cursor: pointer;

  &.active {
    .Switch-outer {
      border-color: var.$colorTextPrimary;
    }
    .Switch-inner {
      background-color: var.$colorTextPrimary;
      left: 18px;
    }
  }
  &-outer {
    height: 24px;
    width: 40px;
    border-radius: 100vw;
    border: 2px solid var.$colorGrey3;
    position: relative;
  }
  &-inner {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: var.$colorGrey3;
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    transition: left 250ms ease-out;
  }
  &-label {
    font-weight: var.$fontSemibold;
    font-size: func.rem(12px);
  }
}
