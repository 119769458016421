@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;

.VideoFrameWrapperDVR {
  &__error {
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    text-align: center;
    font-size: func.rem(32px);
    color: var.$colorWhite;
  }
}
