@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.MomentumBar {
  flex: 1;
  height: 100%;
  position: relative;
  min-width: calc((100vw - 160px - 135px) / 120);

  &:nth-of-type(10n):not(:last-of-type) {
    border-right: 1px solid var.$colorGrey;
  }
  &-inner {
    position: absolute;
    z-index: 10;

    &.home {
      left: 0;
      right: 0;
      bottom: calc(50%);
      background-color: var.$colorHighlightGreen6;

      &.descending {
        background-color: var.$colorHighlightGreen5;
      }
    }
    &.away {
      left: 0;
      right: 0;
      top: calc(50%);
      background-color: var.$colorHighlightRed6;

      &.descending {
        background-color: var.$colorHighlightRed5;
      }
    }
  }
}
