@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.MomentumCharts {
  width: fit-content;
  padding-inline: func.rem(80px);

  &-container {
    position: relative;
    width: 100%;

    &--team {
      position: absolute;
      padding: func.rem(4px);
      border-radius: var.$mediumBorderRadius;
      left: 95px;
      font-size: func.rem(10px);
      font-weight: var.$fontNormal;
      color: var.$colorWhite;
      z-index: 12;

      &.home {
        background-color: var.$colorHighlightGreen5;
      }

      &.away {
        background-color: var.$colorHighlightRed5;
      }
    }
  }
  &-scroll {
    width: 100vw;
    overflow-x: auto;
    margin-inline: auto;
    padding-bottom: 4px;
    position: relative;
  }
  &-header {
    margin-bottom: func.rem(10px);
    position: relative;
    height: 26px;
    margin-left: 16px;

    &--state {
      position: absolute;
      display: grid;
      place-content: center;
    }
  }
  &-chart {
    height: calc(100vh - 42px - 90px - 72px - 16vh);
    border-top: 1px solid var.$colorGrey;
    border-bottom: 1px solid var.$colorGrey;
    position: relative;
    margin-left: 16px;

    &--state {
      position: absolute;
      height: 100%;
    }
    &--bars {
      height: 100%;
      gap: 1px;
      display: flex;
      flex-flow: row nowrap;
    }
  }
  &-footer {
    margin-top: func.rem(10px);
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-left: 16px;

    p {
      font-size: func.rem(12px);
      font-weight: var.$fontNormal;
      text-align: center;

      &:first-of-type {
        margin-left: -20px;
        transform: translateX(20px);
        font-weight: var.$fontSemibold;
      }

      &:last-of-type {
        margin-right: -20px;
        transform: translateX(-20px);
      }

      &:nth-of-type(4n + 1) {
        font-weight: var.$fontSemibold;
      }
    }
  }
}
