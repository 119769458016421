@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsSmallContainer {
  max-width: func.rem(360px);
  display: flex;
  flex-flow: column nowrap;
  gap: func.rem(8px);
  flex: 1;

  &.alone {
    margin-inline: auto;
  }

  &.double {
    max-width: func.rem(728px);
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    & .StatsBox:last-child:not(:nth-child(even)) {
      grid-column: auto / span 2;
      width: func.rem(360px);
      justify-self: center;
    }
  }
}
