@use './01-Utilities/variables' as var;
@use './01-Utilities/functions' as func;
@use './01-Utilities/config' as cfg;
@use './fonts';

@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&display=swap');

/* Default settings */

html * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'JetBrains Mono', monospace !important;
  color: var.$colorTextPrimary;
  font-weight: var.$fontSemibold;
}
a {
  text-decoration: none;
  color: inherit;
}
main {
  padding: func.rem(24px) func.rem(20px) func.rem(20px);
}
.MuiTabPanel-root {
  padding: 0 !important;
  flex: 1;
}
.MuiTab-textColorPrimary {
  color: var.$colorTextPrimary !important;
  font-weight: var.$fontSemibold !important;
  text-transform: none !important;

  &.Mui-selected {
    color: var.$colorHighlightPrimary !important;
  }
}
.MuiTabs-indicator {
  display: none;
}
