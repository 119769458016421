@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.ShotsModal {
  padding: func.rem(24px);
  background-color: var.$colorBackground;
  &-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: func.rem(48px);

    &--team {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: func.rem(16px);

      img {
        height: func.rem(48px);
        width: auto;
      }
      p {
        font-size: func.rem(32px);
      }
    }
    &--activeTeam {
      display: flex;
      flex-flow: row nowrap;
      gap: func.rem(8px);
    }
  }
  &-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: func.rem(16px);
    margin-top: func.rem(48px);
  }
}
