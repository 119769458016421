@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsBigContainer {
  display: flex;
  flex-flow: row nowrap;
  gap: func.rem(8px);
  max-width: var.$contentMaxWidth;
  margin-inline: auto;
  margin-bottom: func.rem(8px);
}
