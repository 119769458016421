@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.PlayerPickerItem {
  width: 148px;
  padding: func.rem(28px) func.rem(26px) func.rem(8px);
  background-color: var.$colorWhite;
  border-radius: var.$mediumBorderRadius;
  border: 2px solid var.$colorWhite;
  position: relative;
  cursor: pointer;

  &.active {
    border-color: var.$colorTextPrimary;
  }
  &-number {
    position: absolute;
    top: -20px;
    left: 50%;
    width: func.rem(40px);
    height: func.rem(40px);
    transform: translateX(-50%);
    border-radius: 50%;
    background-color: var.$colorTextPrimary;
    color: var.$colorWhite;
    font-size: func.rem(14px);
    display: grid;
    place-items: center;
  }
  &-player {
    text-align: center;
    font-size: func.rem(14px);

    span {
      font-weight: var.$fontNormal;
    }
  }
}
