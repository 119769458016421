@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.VideoPagination {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: func.rem(16px);
  margin-bottom: func.rem(32px);

  &-dot {
    width: func.rem(20px);
    height: func.rem(20px);
    border-radius: 50%;
    background-color: var.$colorWhite;
    cursor: pointer;
    border: 2px solid var.$colorWhite;

    &.active {
      background-color: var.$colorHighlightPrimary;
    }
  }
}
