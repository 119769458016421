@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.GoalkeepersMap {
  &-activePlayer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: func.rem(8px);
    margin-bottom: func.rem(6px);

    &--number {
      width: 40px;
      height: 40px;
      background-color: var.$colorBlack;
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: var.$colorWhite;
      font-size: func.rem(14px);
    }
    &--name {
      font-size: func.rem(14px);
      span {
        font-weight: var.$fontNormal;
      }
    }
  }
  &-players {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(8px);
  }
  &-map {
    position: relative;
  }
}
