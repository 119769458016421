@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.MomentumGoal {
  position: absolute;
  background-color: var.$colorWhite;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: grid;
  place-content: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  cursor: pointer;

  &-inner {
    border-radius: 50%;
    display: grid;
    place-content: center;
    font-size: func.rem(12px);
    font-weight: var.$fontSemibold;
    width: 28px;
    height: 28px;

    &.home {
      border: 2px solid var.$colorHighlightGreen5;
      color: var.$colorHighlightGreen5;
      background-color: #c1e4d9;
    }
    &.away {
      border: 2px solid var.$colorHighlightRed5;
      color: var.$colorHighlightRed5;
      background-color: #f7cbcb;
    }
  }
}
