@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.ShotsMap {
  position: relative;
  max-width: func.rem(360px);
  margin-top: func.rem(13px);

  &-title {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var.$colorTextPrimary;
    color: var.$colorWhite;
    text-transform: uppercase;
    font-size: func.rem(10px);
    white-space: nowrap;
    padding: func.rem(6px) func.rem(16px);
    border-radius: 100vw;
    z-index: 2;
  }
  &-container {
    position: relative;
    overflow: hidden;
    height: 276px;

    img {
      width: 100%;
      height: auto;
      margin-bottom: func.rem(-4px);
    }
  }
  &-shot {
    position: absolute;
    width: func.rem(18px);
    height: func.rem(18px);
    background-color: var.$colorDot;
    border: 2px solid var.$colorTextPrimary;
    border-radius: 100vw;
    transform: translate(-50%, -50%);

    &.goal {
      background-color: var.$colorDotGoal;
      border-color: var.$colorHighlightPrimary;
    }
  }
  &-buttons {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(8px);
    margin-top: func.rem(12px);
  }
  &-pickedPlayers {
    display: flex;
    flex-flow: column nowrap;
    gap: func.rem(8px);
    margin-top: func.rem(24px);
  }
}
