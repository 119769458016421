@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.DetailShotDot {
  position: absolute;

  &-dot {
    width: func.rem(28px);
    height: func.rem(28px);
    border: 2px solid var.$colorTextPrimary;
    background-color: var.$colorDot;
    display: grid;
    place-items: center;
    border-radius: 100vw;
    font-size: func.rem(11px);
    font-weight: var.$fontSemibold;
    color: var.$colorTextPrimary;
    transform: translate(-50%, -50%);
    cursor: pointer;

    &.goal {
      border-color: var.$colorHighlightPrimary;
      background-color: var.$colorDotGoal;
      color: var.$colorHighlightPrimary;
    }
    &.catched {
      border-color: var.$colorError;
      background-color: var.$colorDotCatched;
      color: var.$colorError;
    }
    &.blocked {
      border-color: var.$colorSuccess;
      background-color: var.$colorDotBlocked;
      color: var.$colorSuccess;
    }
  }
}
