@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.IceTimeTimeline {
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  background-color: var.$colorWhite;
  padding: 0 func.rem(32px) 0 func.rem(39px);
  box-shadow: 0px -4px 12px 0 rgba(#868e96, 0.12);

  &.top {
    padding-top: func.rem(38px);
    box-shadow: 0px 4px 12px 0 rgba(#868e96, 0.12);

    .IceTimeTimeline-timeStamp::after {
      bottom: 0;
      top: unset;
    }
  }
  &.scrollbar {
    padding-right: func.rem(46px);
  }

  &-labels {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    padding-inline: func.rem(8px);
    width: 350px;

    & > p {
      width: 40px;
      text-align: center;
      font-size: func.rem(12px);
      font-weight: var.$fontSemibold;
      cursor: pointer;

      &:nth-of-type(1) {
        width: unset;
        margin-right: auto;
      }
      &:nth-of-type(2) {
        margin-right: func.rem(8px);
      }
      &.active {
        color: var.$colorHighlightPrimary;
      }
    }

    &--thirdStats {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      & > p {
        width: 40px;
        text-align: center;
        font-size: func.rem(12px);
        font-weight: var.$fontSemibold;
        cursor: pointer;

        &.active {
          color: var.$colorHighlightPrimary;
        }

        &.disclaimer {
          width: unset;
          position: absolute;
          left: 50%;
          top: calc(-100% - 12px);
          transform: translateX(-50%);
          font-size: func.rem(14px);
          color: var.$colorTextSecondary;
        }
      }
    }
  }
  &-time {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
    flex: 1;
    position: relative;
  }
  &-timeStamp {
    position: relative;
    font-size: func.rem(12px);
    font-weight: var.$fontNormal;
    padding: func.rem(8px) 0;

    &::after {
      content: '';
      position: absolute;
      width: 2px;
      height: 8px;
      background-color: var.$colorGrey3;
      left: 50%;
      transform: translateX(-50%);
      top: 0;
    }
    &:first-of-type::after {
      background-color: var.$colorTextPrimary;
    }
    &:last-of-type::after {
      background-color: var.$colorTextPrimary;
    }
  }
  &-state {
    height: 36px;
    position: absolute;
    bottom: 0;
  }
  &-iconContainer {
    margin-top: -20px;
  }
}
