@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.MatchInfo {
  background-color: var.$colorWhite;
  border-radius: var.$defaultBorderRadius;
  overflow: hidden;
  box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.12);
  flex: 1;

  &-info {
    display: flex;
    flex-flow: row nowrap;
  }

  &-league {
    display: block;
    text-align: center;
    font-size: func.rem(11px);
    padding: func.rem(6px);
    color: var.$colorTextSecondary;
    background-color: var.$colorGrey;
  }

  &-teams {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    gap: func.rem(24px);
    padding: func.rem(16px);
    border-right: 1px solid var.$colorBackground;

    &--team {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: func.rem(8px);

      img {
        height: func.rem(48px);
        width: auto;
      }
      p {
        font-size: func.rem(20px);
      }
    }
    &--score {
      font-size: func.rem(32px);
    }
  }
  &-data {
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: func.rem(24px);
    padding: func.rem(16px);

    &--stats p {
      font-size: func.rem(12px);
      white-space: nowrap;
      color: var.$colorTextSecondary;

      span {
        color: var.$colorTextPrimary;
      }
    }
    .Buttons {
      padding-inline: func.rem(24px) !important;
    }
  }
}
