@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.PaginationButton {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: func.rem(8px);
  min-width: func.rem(100px);
  cursor: pointer;

  p {
    font-size: func.rem(12px);
  }

  &.next {
    flex-flow: row-reverse nowrap;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
