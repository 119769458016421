@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.LoginInput {
  display: flex;
  flex-flow: column nowrap;

  &-title {
    font-size: func.rem(12px);
    padding-bottom: func.rem(4px);
    color: var.$colorLoginText;
  }
  &-inner {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: func.rem(7px);
    padding-inline: func.rem(12px);
    border-radius: var.$mediumBorderRadius;
    border: 1px solid var.$colorLoginGray2;

    &.focused {
      border: 1px solid var.$colorLoginBlue3;
      box-shadow: 0px 0px 0px 3px var.$colorLoginBlue2;
    }
    &.error {
      border: 1px solid var.$colorLoginRed;
      box-shadow: 0px 0px 0px 3px var.$colorLoginRed2;

      input {
        color: var.$colorLoginRed;
      }
    }
    input {
      outline: 0;
      border: 0;
      padding: func.rem(14px) 0;
      flex: 1;
      font-size: func.rem(14px);
      font-weight: var.$fontNormal;
      color: var.$colorLoginText;

      &::placeholder {
        color: var.$colorLoginGray;
        font-size: func.rem(14px);
        font-weight: var.$fontNormal;
      }
    }
  }
  &-showPassword {
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}
