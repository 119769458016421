@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.IceTimeShift {
  position: absolute;
  height: 18px;
  top: 50%;
  transform: translateY(-50%);
  background-color: var.$colorTextPrimary;
  z-index: 2;

  &.showTooltip &-tooltip {
    display: unset;
  }
  &-tooltipContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }
  &-tooltip {
    position: absolute;
    background-color: var.$colorWhite;
    box-shadow: 0px 4px 8px 0 rgba(#868e96, 0.4);
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    width: fit-content;
    padding: func.rem(6px);
    border-radius: var.$defaultBorderRadius;
    display: none;
    z-index: 4;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: var.$colorWhite transparent transparent transparent;
    }

    p {
      white-space: nowrap;
      font-size: func.rem(10px);
      font-weight: var.$fontNormal;
    }
  }
  &-shot {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var.$colorWhite;
    box-shadow: 0px 4px 8px 0 rgba(#868e96, 1);
    display: grid;
    place-items: center;
    font-weight: var.$fontSemibold;
    font-size: func.rem(12px);
    z-index: 3;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
