@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.TopHeader {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-inline: func.rem(20px);
  border-bottom: 2px solid var.$colorGrey2;

  p {
    font-size: func.rem(12px);
  }

  &-stats {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(20px);

    &--basicTab {
      padding: func.rem(12px) 0;
      padding-right: func.rem(20px);
      //border-right: 2px solid var.$colorGrey2;
      p {
        color: var.$colorTextSecondary;
      }
      .highlight {
        color: var.$colorTextPrimary;
      }
    }

    &--iconTab {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      gap: func.rem(6px);

      &.success {
        p {
          color: var.$colorSuccess;
        }
      }
      &.error {
        p {
          color: var.$colorError;
        }
      }
    }
  }
  &-buttons {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: func.rem(20px);
  }
  .MuiButton-text {
    color: var.$colorTextPrimary;
    text-transform: none;
    font-size: func.rem(12px);
    font-weight: var.$fontSemibold;

    &:hover,
    &:focus {
      background-color: unset;
    }
  }
}
