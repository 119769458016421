@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.BugReport {
  .MuiPaper-root {
    display: block;
    border-radius: var.$defaultBorderRadius;
    background-color: var.$colorBackground;
    padding: func.rem(24px);
  }

  &-header {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: func.rem(8px);
    margin-bottom: func.rem(24px);

    h1 {
      font-weight: var.$fontSemibold;
      font-size: func.rem(22px);
    }

    p {
      font-weight: var.$fontSemibold;
      font-size: func.rem(14px);
      text-transform: uppercase;
      color: var.$colorTextSecondary;
    }
  }

  &-message {
    width: 100%;
    outline: 0;
    border: func.rem(2px) solid var.$colorTextPrimary;
    border-radius: var.$mediumBorderRadius;
    padding: func.rem(24px);
    font-weight: var.$fontNormal;
    font-size: func.rem(14px);
    margin-top: func.rem(24px);
  }

  &-buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    gap: func.rem(8px);
    margin-top: func.rem(24px);
  }
}
