@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.ChangelogModal {
  .MuiPaper-root {
    border-radius: var.$defaultBorderRadius;
    background-color: var.$colorBackground;
    padding: func.rem(40px);
  }
}

.Changelog {
  display: flex;
  flex-direction: column;
  gap: func.rem(24px);

  & * {
    font-family:
      'Inter',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      sans-serif !important;
    font-size: func.rem(16px);
    font-weight: var.$fontNormal;
  }

  ul {
    padding-left: func.rem(20px);
    margin: 0;

    li {
      margin-bottom: func.rem(5px);
      line-height: 24px;
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    overflow: auto;
    max-height: 600px;
  }

  &-versionHeader {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: func.rem(14px);
    gap: func.rem(2px);

    h3 {
      font-size: func.rem(24px);
      font-weight: var.$fontSemibold;
      color: var.$colorLoginBlue;
    }

    p {
      font-size: func.rem(12px);
      color: var.$colorLoginGray;
    }
  }
}
