@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.MatchInfoHeader {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: func.rem(16px) func.rem(20px);
  gap: func.rem(28px);

  p {
    font-size: func.rem(32px);
  }

  &-team {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: func.rem(12px);

    img {
      height: func.rem(56px);
      width: auto;
    }
  }
}
