@use '../../01-Utilities/variables' as var;
@use '../../01-Utilities/functions' as func;
@use '../../01-Utilities/config' as cfg;

.StatsDetailRow {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  gap: func.rem(8px);

  &.switchPlace {
    flex-flow: row-reverse nowrap;

    .StatsDetailRow-playerInfo {
      align-items: flex-end;
      flex-flow: row-reverse nowrap;
    }
    .StatsDetailRow-playerText {
      text-align: right;
    }
  }

  &-stat {
    height: func.rem(48px);
    width: func.rem(48px);
    border-radius: var.$mediumBorderRadius;
    display: grid;
    place-content: center;
    font-size: func.rem(14px);

    &.color1 {
      background-color: var.$colorStats1;
    }
    &.color2 {
      background-color: var.$colorStats2;
    }
    &.color3 {
      background-color: var.$colorStats3;
    }
    &.color4 {
      background-color: var.$colorStats4;
    }
  }
  &-playerText {
    display: flex;
    flex-flow: column nowrap;
    gap: func.rem(4px);
    &--player {
      font-size: func.rem(14px);
      span {
        font-weight: var.$fontNormal;
      }
    }
    &--role {
      font-size: func.rem(12px);
      color: var.$colorTextSecondary;
      text-transform: uppercase;
    }
  }
  &-playIcon {
    width: func.rem(40px);
    height: func.rem(40px);
    border-radius: 50%;
    background-color: var.$colorWhite;
    display: grid;
    place-items: center;
    cursor: pointer;

    &.disabled {
      cursor: default;
      opacity: 0.1;
    }
  }
  &-playerInfo {
    display: flex;
    flex-flow: row nowrap;
    gap: func.rem(12px);
  }
}
